import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "./assets/logo.svg";
import { ReactComponent as BackArrow } from "./assets/back-arrow.svg";
import { ReactComponent as CheckMark } from "./assets/check-mark.svg";
import rightArrow from "./assets/right-arrow.svg";
import { ReactComponent as VolumeDiscount } from "./assets/volume-discount-2.svg";
import Confetti from "react-confetti";
import { X } from "lucide-react";

const CheckIcon = ({ className }) => (
  <svg className={className} viewBox="0 0 24 24">
    <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41L9 16.17z" />
  </svg>
);

const plantsDatabase = [
  {
    id: 1,
    name: "Red Anthurium",
    description: "Tropical charm with vibrant blooms",
    benefits: "Vibrant blooms, long-lasting, tropical charm",
    selectedColor: "pink",
    colorImagePath: "/plantImages/red-anthurium-{color}.jpg",
    price: 55,
    strikeoutPrice: 69,
  },
  {
    id: 2,
    name: "Snake Plant",
    description: "Purify air while you sleep",
    selectedColor: "white",
    colorImagePath: "/plantImages/snake-plant-{color}.jpg",
    price: 49,
    strikeoutPrice: 65,
  },
  {
    id: 3,
    name: "Monstera",
    description: "Bring the rainforest home",
    selectedColor: "pink",
    colorImagePath: "/plantImages/monstera-{color}.jpg",
    price: 55,
    strikeoutPrice: 65,
  },
  {
    id: 4,
    name: "Pothos",
    description: "Lush greenery, minimal effort",
    selectedColor: "pink",
    colorImagePath: "/plantImages/pothos-{color}.jpg",
    price: 45,
    strikeoutPrice: 59,
  },
  {
    id: 5,
    name: "Rubber Tree",
    description: "Resilient beauty with bold foliage",
    benefits: "Air-purifying, resilient, bold foliage",
    selectedColor: "pink",
    colorImagePath: "/plantImages/rubber-tree-{color}.jpg",
    price: 59,
    strikeoutPrice: 79,
  },
  {
    id: 6,
    name: "Red Dracaena",
    description: "Add a pop of color effortlessly",
    benefits: "Striking color, low-maintenance, air purifier",
    price: 65,
    strikeoutPrice: 85,
    selectedColor: "pink",
    colorImagePath: "/plantImages/red-dracaena-{color}.jpg",
  },
];

const PlantSelection = ({ progress, data, setData, amplitude }) => {
  const [selectedPlants, setSelectedPlants] = useState([]);
  const [loadedImages, setLoadedImages] = useState({});
  const [showPopup, setShowPopup] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);
  const [plants, setPlants] = useState(plantsDatabase);
  const [discountPercentage, setDiscountPercentage] = useState(0);
  const [fullPrice, setFullPrice] = useState(0);
  const [discountedPrice, setDiscountedPrice] = useState(0);
  const [filters, setFilters] = useState([]);
  const navigate = useNavigate();

  const [popupAlreadyShown, setPopupAlreadyShown] = useState(() => {
    const stored = sessionStorage.getItem("popupAlreadyShown");
    return stored ? JSON.parse(stored) : false;
  });

  const getSelectedPlantsData = (selectedPlantsValue) => {
    return plants.filter((plant) => selectedPlantsValue.includes(plant.id));
  };

  const getFullPrice = (selectedPlantsValue) => {
    const selectedPlantsData = getSelectedPlantsData(selectedPlantsValue);
    const fullPriceValue = selectedPlantsData.reduce((sum, plant) => {
      const fullPriceSummer = plant.price;
      return sum + fullPriceSummer;
    }, 0);

    return fullPriceValue;
  };

  useEffect(() => {
    console.log(data);
    if (data && data.plants && data.plants.length > 0) {
      setPlants((prevPlants) =>
        prevPlants.map((plant) => {
          const matchingDataPlant = data.plants.find(
            (dataPlant) => dataPlant.id === plant.id
          );

          // If there's a matching plant, update the color, otherwise keep it unchanged
          if (matchingDataPlant && matchingDataPlant.selectedColor) {
            return { ...plant, selectedColor: matchingDataPlant.selectedColor };
          }
          return plant;
        })
      );

      setSelectedPlants(data.plants.map((plant) => plant.id));
    }

    setFilters([]);

    if (data.hasKids) {
      setFilters((prevFilters) => [...prevFilters, "Kids friendly"]);
    }

    if (data.hasPets) {
      setFilters((prevFilters) => [...prevFilters, "Pets friendly"]);
    }

    if (data.lightConditions === "medium") {
      setFilters((prevFilters) => [...prevFilters, "Medium light"]);
    }

    if (data.lightConditions === "low") {
      setFilters((prevFilters) => [...prevFilters, "Low light"]);
    }

    if (data.lightConditions === "bright") {
      setFilters((prevFilters) => [...prevFilters, "Bright direct light"]);
    }

    // Regardless of filters
    setFilters((prevFilters) => [...prevFilters, '6" self watering pot']);
  }, []);

  useEffect(() => {
    /* not show popup - for no - because it might be confusing
    if (selectedPlants.length === 1) {
      if (!popupAlreadyShown) {
        setShowPopup(true);
        setShowConfetti(true);
        setPopupAlreadyShown(true);
        sessionStorage.setItem("popupAlreadyShown", JSON.stringify(true));
      }
    }*/

    const fullPriceValue = getFullPrice(selectedPlants);
    setFullPrice(fullPriceValue);

    let discountPercentageValue = 0;
    if (selectedPlants.length === 1) {
      discountPercentageValue = 10;
    } else if (selectedPlants.length === 2) {
      discountPercentageValue = 15;
    } else {
      discountPercentageValue = 25;
    }

    setDiscountPercentage(discountPercentageValue);

    let discountedPriceValue = (
      parseFloat(fullPriceValue) -
      parseFloat(fullPriceValue) * (discountPercentageValue / 100)
    ).toFixed(0);

    setDiscountedPrice(discountedPriceValue);
  }, [
    selectedPlants,
    getFullPrice,
    popupAlreadyShown,
    setShowPopup,
    setShowConfetti,
    setPopupAlreadyShown,
    setDiscountPercentage,
    setDiscountedPrice,
  ]);

  const togglePlantSelection = (plantId) => {
    setSelectedPlants((prev) => {
      let newSelectedPlants = [...prev];

      if (prev.includes(plantId)) {
        newSelectedPlants = prev.filter((id) => id !== plantId);
        amplitude.track("Plant Removed", { "Plant Id": plantId });
      } else {
        newSelectedPlants = [...prev, plantId];
        amplitude.track("Plant Added", { "Plant Id": plantId });

        const eventFiredKey = "plantAddedConversionFired";
        if (!sessionStorage.getItem(eventFiredKey)) {
          // Fire the Facebook Pixel conversion event
          if (window.fbq) {
            window.fbq("track", "PlantAdded", {
              plantsCount: newSelectedPlants ? newSelectedPlants.length : 1,
            });
          }

          if (typeof window.gtag === "function") {
            window.gtag("event", "conversion", {
              send_to: "AW-1063169684/t3kYCLmJpMYZEJTd-voD",
            });
          }

          // Mark the event as fired in sessionStorage
          sessionStorage.setItem(eventFiredKey, "true");
        }
      }

      return newSelectedPlants;
    });
  };

  const getColorCode = (color) => {
    switch (color) {
      case "pink":
        return "rgb(245 213 206)";
      case "white":
        return "#fff";
      case "brown":
        return "rgb(201 190 175)";
      case "blue":
        return "rgb(172 202 198)";
      default:
        return "#000";
    }
  };

  const changePlantColor = (plantId, color) => {
    setPlants((prev) => {
      return prev.map((plant) => {
        if (plant.id === plantId) {
          console.log("changing color of plant", plantId, "to", color);
          return { ...plant, selectedColor: color };
        }
        return plant;
      });
    });
  };

  const handleImageLoad = (plantId) => {
    setLoadedImages((prev) => ({ ...prev, [plantId]: true }));
  };

  const handleNext = () => {
    const selectedPlantsData = getSelectedPlantsData(selectedPlants);

    setData((prev) => ({
      ...prev,
      plants: selectedPlantsData,
      fullPrice,
      discountedPrice,
      discountPercentage,
    }));
    navigate("/checkout");
  };

  const closePopup = () => {
    setShowPopup(false);
    setShowConfetti(false);
  };

  return (
    <div className="page">
      {showConfetti && (
        <div className="plant-selection-confetti-container">
          <Confetti
            recycle={false}
            numberOfPieces={300}
            gravity={0.1}
            initialVelocityY={0}
            initialVelocityX={20}
            wind={0.0}
            confettiSource={{
              x: window.innerWidth / 2,
              y: -50,
            }}
            tweenDuration={4350}
          />
        </div>
      )}
      <div className="notificationheader">
        <Link to="/light-conditions" className="back-button">
          <BackArrow width="24" height="24" />
        </Link>
        <div className="notificationtext">90-day guarantee for all plants</div>
      </div>
      <div className="progress" style={{ width: `${progress}%` }} />
      <div className="content hidden visibleAnimation plant-selection-content">
        <img className="logo-icon" alt="" src={logo} />
        <div className="title">You've got 6 matches</div>
        <div className="subtitle">
          These 6 plants will thrive in your home, based on your answers:
        </div>
        <div className={"plant-selection-searching-plants-filters-list"}>
          {filters.map((filter, index) => (
            <div
              key={index}
              className="plant-selection-searching-plants-filter-item"
            >
              <CheckIcon className="searching-plants-check-icon" />
              <span>{filter}</span>
            </div>
          ))}
        </div>
        <div className="plant-selection-grid">
          {plants.map((plant) => (
            <div
              key={plant.id}
              className={`plant-selection-option ${
                selectedPlants.includes(plant.id)
                  ? "plant-selection-option-selected"
                  : ""
              }`}
              onClick={() => togglePlantSelection(plant.id)}
            >
              <div className="plant-selection-image-container">
                {!selectedPlants.includes(plant.id) ? (
                  <div className="plant-selection-add-button">+</div>
                ) : null}

                <div className="plant-selection-image-placeholder"></div>
                <img
                  src={plant.colorImagePath.replace(
                    "{color}",
                    plant.selectedColor
                  )}
                  alt={plant.name}
                  className={`plant-selection-image ${
                    loadedImages[plant.id] ? "plant-selection-image-loaded" : ""
                  }`}
                  style={{ height: [2, 5].includes(plant.id) ? "92%" : "100%" }}
                  onLoad={() => handleImageLoad(plant.id)}
                />
              </div>
              <div
                className="plant-selection-colors"
                onClick={(e) => e.stopPropagation()}
              >
                {["pink", "white", "brown", "blue"].map((color) => (
                  <div
                    key={color}
                    className={`plant-selection-color ${
                      plant.selectedColor === color
                        ? "plant-selection-color-selected"
                        : ""
                    }`}
                    style={{ backgroundColor: getColorCode(color) }}
                    onClick={(e) => {
                      e.stopPropagation();
                      changePlantColor(plant.id, color);
                    }}
                  ></div>
                ))}
              </div>
              <div className="plant-selection-info-overlay">
                <h3 className="plant-selection-name">{plant.name}</h3>
                <p className="plant-selection-description">
                  {plant.description}
                </p>
                <div className="plant-selection-price-tag">
                  <span className="plant-selection-original-price">
                    ${plant.strikeoutPrice}
                  </span>
                  <span className="plant-selection-discounted-price">
                    ${plant.price}
                  </span>
                  <span className="plant-selection-discount-percentage">
                    SALE
                  </span>
                </div>
              </div>
              {selectedPlants.includes(plant.id) && (
                <div className="plant-selection-check-mark">
                  <CheckMark width="24" height="24" />
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
      {selectedPlants.length >= 1 && (
        <div className="select-plants-next-fixed-bottom-container">
          <div className="select-plants-next-discount-message">
            {selectedPlants.length === 1 &&
              "Add 1 more plant to get 15% off your entire order!"}
            {selectedPlants.length === 2 &&
              "Add 1 more plant to get 25% off your entire order!"}
            {selectedPlants.length >= 3 &&
              "You've got the maximum discount of 25% off!"}
          </div>
          <div className="select-plants-next-discount-levels">
            <div
              className={`select-plants-next-discount-square ${
                selectedPlants.length >= 1 ? "active" : ""
              }`}
            >
              10%
            </div>
            <div
              className={`select-plants-next-discount-square ${
                selectedPlants.length >= 2 ? "active" : ""
              }`}
            >
              15%
            </div>
            <div
              className={`select-plants-next-discount-square ${
                selectedPlants.length >= 3 ? "active" : ""
              }`}
            >
              25%
            </div>
          </div>
          <div className="select-plants-next-bottom-content">
            <div className="select-plants-next-total-price">
              <div className="select-plants-next-total-price-numbers">
                <div className="select-plants-next-total-price-strikeout">
                  ${fullPrice}
                </div>
                <div className="select-plants-next-total-price-price">
                  ${discountedPrice}
                </div>
              </div>
              <div className="select-plants-next-total-price-label">
                For {selectedPlants.length} self watering plant
                {selectedPlants.length > 1 && "s"}
              </div>
            </div>
            <div onClick={handleNext} className="cta cta-checkout">
              <div className="cta-text">Checkout</div>
              <img className="right-arrow" alt="" src={rightArrow} />
            </div>
          </div>
        </div>
      )}
      {showPopup && (
        <div className="plant-selection-popup-overlay" onClick={closePopup}>
          <div
            className="plant-selection-popup-content"
            onClick={(e) => e.stopPropagation()}
          >
            <button
              className="plant-selection-close-popup"
              onClick={closePopup}
            >
              <X size={24} />
            </button>
            <VolumeDiscount
              width="100%"
              height="100%"
              className="plant-selection-volume-discount-offer"
              onClick={closePopup}
            />
            <button
              className="plant-selection-continue-shopping"
              onClick={closePopup}
            >
              Continue Shopping
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default React.memo(PlantSelection);
