import React, { useRef, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "./assets/logo.svg";
import { ReactComponent as BackArrow } from "./assets/back-arrow.svg";
import rightArrow from "./assets/right-arrow.svg";

const PetsFriendly = ({ progress, data, setData, resources }) => {
  const navigate = useNavigate();
  const [imageLoaded, setImageLoaded] = useState(false);

  const handleChoice = (hasPets) => {
    setData({ ...data, hasPets });
    navigate("/light-conditions");
  };

  return (
    <div className="page question-with-image">
      <div className="notificationheader">
        <Link to="/kids-friendly" className="back-button">
          <BackArrow width="24" height="24" />
        </Link>
        <div className="notificationtext">10% Off special on all plants!</div>
      </div>
      <div className="progress" style={{ width: `${progress}%` }} />
      <div className="content">
        <img className="logo-icon" alt="" src={logo} />
        <div className="title">Pet-Friendly Plants</div>
        <div className="subtitle">
          Some plants can be harmful if chewed. Do you have curious cats or
          playful pups at home?
        </div>
        <img
          className={`pets-photo image-loading ${
            imageLoaded ? "image-loaded" : ""
          }`}
          src={
            "https://res.cloudinary.com/djlwg6kex/image/upload/pets_yfmplg.jpg"
          }
          onLoad={() => {
            setImageLoaded(true);
          }}
        />

        <div className="button-container">
          <div onClick={() => handleChoice(true)} className="cta">
            <div className="cta-text">Yes, I have pets!</div>
            <img className="right-arrow" alt="" src={rightArrow} />
          </div>
          <div onClick={() => handleChoice(false)} className="cta">
            <div className="cta-text">No furry friends yet</div>
            <img className="right-arrow" alt="" src={rightArrow} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(PetsFriendly);
