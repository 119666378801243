import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "./assets/logo.svg";
import { ReactComponent as BackArrow } from "./assets/back-arrow.svg";
import { ReactComponent as Spinner } from "./assets/spinner.svg";

const OutOfStock = ({ progress, data, amplitude }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    amplitude.track("Shipping Next", { "Order Value": data.discountedPrice });

    const eventFiredKey = "outOfStockConversionFired";

    if (!sessionStorage.getItem(eventFiredKey)) {
      // Fire the Facebook Pixel conversion event
      if (window.fbq) {
        window.fbq("track", "OutOfStockConversion", {
          value: data.discountedPrice, // Numeric value to be sent to Facebook Pixel
          currency: "USD", // You can change the currency if needed
        });
      }

      // Mark the event as fired in sessionStorage
      sessionStorage.setItem(eventFiredKey, "true");
    }

    const loadingTimer = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => {
      clearTimeout(loadingTimer);
    };
  }, []);

  const handleEmailUs = () => {
    amplitude.track("Email Us Clicked", {
      "Order Value": data.discountedPrice,
    });
    window.location.href = "mailto:hello@selfwateringplants.com";
  };

  return (
    <div className="page">
      <div className="notificationheader">
        <Link to="/shipping-information" className="back-button">
          <BackArrow width="24" height="24" />
        </Link>
        <div className="notificationtext">Order Status</div>
      </div>
      <div className="progress" style={{ width: `${progress}%` }} />
      <div className="content checkout-content">
        <img className="logo-icon" alt="" src={logo} />
        {loading ? (
          <div className="oos-loading-spinner">
            <Spinner width="50" height="50" />
          </div>
        ) : (
          <div className={`oos-message`}>
            <div className="title">We're Sorry!</div>
            <p className="subtitle">
              We are so sorry but our plants are currently out of stock due to
              high demand 😟
            </p>
            <p className="subtitle">
              Rest assured, None of your payment or shipping information was
              saved.
            </p>
            <p className="subtitle">
              We will notify you once we are back in stock. Thank you for your
              interest in our products! 🙏
            </p>

            <div className="button-container oos-button-container">
              <div onClick={() => navigate("/")} className="cta oos-button">
                <div className="cta-text">Return to Home</div>
              </div>
              <div
                onClick={() => handleEmailUs(false)}
                className="cta oos-button oos-email-us-button"
              >
                <div className="cta-text ">Email Us</div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default React.memo(OutOfStock);
