import React from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "./assets/logo.svg";
import { ReactComponent as BackArrow } from "./assets/back-arrow.svg";
import { ReactComponent as BackArrowBlack } from "./assets/back-arrow-black.svg";
import { ReactComponent as CheckMark } from "./assets/check-mark-black.svg";
import rightArrow from "./assets/right-arrow.svg";
import { ReactComponent as ShippingIcon } from "./assets/shipping-icon.svg";
import { ReactComponent as GuaranteeIcon } from "./assets/guarantee-icon.svg";

const Checkout = ({ progress, data, amplitude }) => {
  const navigate = useNavigate();

  const handlePayment = () => {
    // Handle payment logic here
    amplitude.track("Checkout Next", { "Order Value": data.discountedPrice });
    navigate("/shipping-information");
  };

  return (
    <div className="page">
      <div className="notificationheader">
        <Link to="/plant-selection" className="back-button">
          <BackArrow width="24" height="24" />
        </Link>
        <div className="notificationtext">Complete your order</div>
      </div>
      <div className="progress" style={{ width: `${progress}%` }} />
      <div className="content checkout-content">
        <img className="logo-icon" alt="" src={logo} />
        <div className="title">Order Summary</div>

        <div className="subtitle">
          Review your plant selection before payment
        </div>
        <div
          className={"plant-selection-searching-plants-filters-list"}
          style={{ padding: "0px", marginBottom: "16px" }}
        >
          <div
            className="plant-selection-searching-plants-filter-item"
            style={{ background: "none", padding: "0px 5px" }}
          >
            <ShippingIcon className="searching-plants-check-icon" />
            <span>Free shipping included</span>
          </div>
          <div
            className="plant-selection-searching-plants-filter-item"
            style={{
              borderLeft: "1px solid rgb(209 209 209)",
              background: "none",
              padding: "0px 5px",
            }}
          >
            <GuaranteeIcon className="searching-plants-check-icon" />
            <span>90-day plant guarantee</span>
          </div>
        </div>
        <div className="checkout-summary">
          {data.plants.map((plant) => {
            return (
              <div key={plant.id} className="checkout-plant-item">
                <img
                  src={plant.colorImagePath.replace(
                    "{color}",
                    plant.selectedColor
                  )}
                  alt={plant.name}
                  className="checkout-plant-image"
                />
                <div className="checkout-plant-details">
                  <h3>{plant.name}</h3>
                  <p>6" self watering pot ({plant.selectedColor})</p>
                  <p className="checkout-plant-price">${plant.price}</p>
                </div>
              </div>
            );
          })}
          <div className="checkout-total">
            <div className="checkout-subtotal">
              <span className="checkout-label">Subtotal:</span>
              <span className="checkout-value">${data.fullPrice}</span>
            </div>
            <div className="checkout-discount">
              <span className="checkout-label">
                Holiday Discount ({data.discountPercentage}%):
              </span>
              <span className="checkout-value">
                -${(data.fullPrice - data.discountedPrice).toFixed(0)}
              </span>
            </div>
            <div className="checkout-discount">
              <span className="checkout-label">Nationwide Shipping:</span>
              <span className="checkout-value">Free</span>
            </div>
            <div className="checkout-discount">
              <span className="checkout-label">Healthy Plant Guarantee:</span>
              <CheckMark width="12" height="12" />
              <span className="checkout-value">Included</span>
            </div>
            <div className="checkout-discount">
              <span className="checkout-label">Lifetime Plant Support:</span>
              <CheckMark width="12" height="12" />
              <span className="checkout-value">Included</span>
            </div>
            <div className="checkout-final-total">
              <span className="checkout-label">Total:</span>
              <span className="checkout-value">${data.discountedPrice}</span>
            </div>
          </div>
        </div>
      </div>

      <div className="select-plants-next-fixed-bottom-container">
        <div className="select-plants-next-bottom-content">
          <Link to="/plant-selection" className="select-plants-next-total-back">
            <BackArrowBlack width="36" height="36" />
          </Link>
          <div className="select-plants-next-total-price">
            <div className="select-plants-next-total-price-price">
              ${data.discountedPrice}
            </div>
            <div className="select-plants-next-total-price-label">
              Total for {data.plants.length} plant
              {data.plants.length > 1 ? "s" : ""}
            </div>
          </div>
          <div onClick={handlePayment} className="cta cta-checkout">
            <div className="cta-text">Next</div>
            <img className="right-arrow" alt="" src={rightArrow} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(Checkout);
