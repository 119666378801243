import React, { useRef, useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "./assets/logo.svg";
import rightArrow from "./assets/right-arrow.svg";
import mediaOutletsImage from "./assets/media-outlets.png";
import "./App.css";
import MuxPlayer from "@mux/mux-player-react";

const Home = ({ progress }) => {
  const location = useLocation();
  const [highPage, setHighPage] = useState(false);

  const isSwpCareCampaign = () => {
    const params = new URLSearchParams(location.search);
    return params.get("utm_campaign") === "swp_care";
  };

  const isYoutubeVacationCampaign = () => {
    const params = new URLSearchParams(location.search);
    return params.get("utm_campaign") === "youtube_waterwhileaway";
  };

  const isPlantCareWebsitesCampaign = () => {
    const params = new URLSearchParams(location.search);
    return params.get("utm_campaign") === "display_Websites_test1";
  };

  const isPlantIdAppsCampaign = () => {
    const params = new URLSearchParams(location.search);
    return params.get("utm_campaign") === "display_plant_apps_1";
  };

  const muxVideos = [
    "sgY00dUvAbDbzeRIJC201fBThMBnZxSj9nc7qym2sVa00E",
    "j1aavHXdPtOUoQom9XxBGTAmxIcjn1GI3vlUhMaEajA",
    "2VLvMwjtPNQ4tSrk5NtOxqdL0154YXZIz6Uz02mUlV6014",
    "ykbvpAIKJTP01BEESLMC6015x00nyIrrRT6oKGRv801rtRQ",
    "A4GoOxlu2fwcUwzcq16UjeFrYPX8vgBR252gJAG7zCQ",
    "TX022w802r901gXxEAegwuTSHMabXOUXTs3fPtqkpKMeZw",
    "01PaCmON8j8MZmcDJhODTbXriU01q51oJzWjmBDlp00ZqI",
  ];

  const videoRefs = useRef([]);

  useEffect(() => {
    if (
      isSwpCareCampaign() ||
      isPlantCareWebsitesCampaign() ||
      isPlantIdAppsCampaign() ||
      isYoutubeVacationCampaign()
    ) {
      setHighPage(true);
    }

    videoRefs.current.forEach((player, index) => {
      if (player) {
        const observer = new IntersectionObserver(
          (entries) => {
            entries.forEach((entry) => {
              const player = entry.target;
              if (entry.isIntersecting) {
                try {
                  player.play();
                } catch (e) {}
              } else {
                try {
                  player.pause();
                } catch (e) {}
              }
            });
          },
          { threshold: 0.5 } // Trigger when 75% of the video is visible
        );

        // Observe each MuxPlayer
        videoRefs.current.forEach((player) => {
          if (player) {
            observer.observe(player);
          }
        });

        // Cleanup on unmount
        return () => {
          videoRefs.current.forEach((player) => {
            if (player) {
              observer.unobserve(player);
            }
          });
        };
      }
    });

    sessionStorage.setItem("popupAlreadyShown", JSON.stringify(false));
  }, []);

  return (
    <div className={`page ${highPage ? "highPage" : ""}`}>
      <div className="notificationheader">
        <div className="notificationtext">
          Holiday exclusive - Free shipping on all plants!
        </div>
      </div>
      <div className="progress" style={{ width: `${progress}%` }} />
      <div className="content">
        <img className="logo-icon" alt="" src={logo} />
        {isSwpCareCampaign() ||
        isPlantCareWebsitesCampaign() ||
        isPlantIdAppsCampaign() ? (
          <div className="title-smaller">
            Never worry about plant care again
          </div>
        ) : isYoutubeVacationCampaign() ? (
          <div className="title-smaller">Put your plants on autopilot</div>
        ) : (
          <div className="title">Plant care made easy</div>
        )}

        {(isSwpCareCampaign() ||
          isPlantCareWebsitesCampaign() ||
          isPlantIdAppsCampaign()) && (
          <div className="subtitle">
            Our plants come in a unique self-watering pot that makes them thrive
            hassle-free!
          </div>
        )}

        {isYoutubeVacationCampaign() && (
          <div className="subtitle">
            Going on vacation? Our unique self watering plants thrive
            hassle-free!
          </div>
        )}

        <div className="slider">
          <div className="carousel">
            {[...muxVideos].map((muxId, index) => (
              <MuxPlayer
                className="video-player"
                ref={(el) => (videoRefs.current[index] = el)}
                streamType="on-demand"
                playbackId={muxId}
                primaryColor="#FFFFFF"
                secondaryColor="#FFFFFF"
                playbackRate={1.15}
                autoplay={false} // Disable autoplay to manually control playback
                muted={true}
                controls={false}
                poster={`https://image.mux.com/${muxId}/thumbnail.png?time=0`}
              />
            ))}
          </div>
        </div>
        <Link to="/how-it-works" className="cta">
          <div className="cta-text">Get Started</div>
          <img className="right-arrow" alt="" src={rightArrow} />
        </Link>
        <div className="media-outlets">
          <div className="media-outlets-title">
            As covered on top media outlets
          </div>
          <img
            className="media-outlets-image"
            src={mediaOutletsImage}
            alt="Media outlets"
          />
        </div>
      </div>
    </div>
  );
};

export default React.memo(Home);
