import React, { useRef, useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import logo from "./assets/logo.svg";
import rightArrow from "./assets/right-arrow.svg";
import "./App.css";
import Home from "./Home";
import HowItWorks from "./HowItWorks";
import KidsFriendly from "./KidsFriendly";
import PetsFriendly from "./PetsFriendly";
import PlantSelection from "./PlantSelection";
import LightConditions from "./LightConditions";
import LoadingScreen from "./LoadingScreen";
import Checkout from "./Checkout";
import ShippingInformation from "./ShippingInformation";
import OutOfStock from "./OutOfStock";
import petsPhoto from "./assets/pets.jpg";
import familyPhoto from "./assets/kids-friendly-155.jpg";
import lightExplainer from "./assets/light-explainer.jpg";
import * as amplitude from "@amplitude/analytics-browser";
import { sessionReplayPlugin } from "@amplitude/plugin-session-replay-browser";

const resources = {
  petsPhoto,
  familyPhoto,
  lightExplainer,
};

const RouteHandler = () => {
  const [data, setData] = useState({
    hasKids: false,
    hasPets: false,
    lightConditions: "bright", // low / medium / bright
    plants: [],
    fullPrice: 0,
    discountedPrice: 0,
  });

  const [progress, setProgress] = useState(0);
  const location = useLocation();
  const totalPages = 6;

  const updateProgress = (currentPage) => {
    setProgress(currentPage * (100 / totalPages)); // Increase by 25% each time, max 100%
  };

  useEffect(() => {
    const pages = [
      { page: "/", number: 0 },
      { page: "/how-it-works", number: 1 },
      { page: "/kids-friendly", number: 2 },
      { page: "/pets-friendly", number: 3 },
      { page: "/light-conditions", number: 4 },
      { page: "/loading-screen", number: 5 },
      { page: "/plant-selection", number: 6 },
      { page: "/checkout", number: 7 },
      { page: "/shipping-information", number: 8 },
      { page: "/out-of-stock", number: 9 },
    ];

    const currentPage = pages.find(
      (page) => page.page === location.pathname
    )?.number;
    setProgress(currentPage * (100 / pages.length));
  }, [location.pathname]);

  return (
    <Routes>
      <Route
        path="/"
        element={<Home progress={progress} data={data} setData={setData} />}
      />
      <Route
        path="/how-it-works"
        element={
          <HowItWorks progress={progress} data={data} setData={setData} />
        }
      />
      <Route
        path="/kids-friendly"
        element={
          <KidsFriendly
            progress={progress}
            data={data}
            setData={setData}
            resources={resources}
          />
        }
      />
      <Route
        path="/pets-friendly"
        element={
          <PetsFriendly
            progress={progress}
            data={data}
            setData={setData}
            resources={resources}
          />
        }
      />
      <Route
        path="/light-conditions"
        element={
          <LightConditions
            progress={progress}
            data={data}
            setData={setData}
            resources={resources}
          />
        }
      />
      <Route
        path="/loading-screen"
        element={
          <LoadingScreen progress={progress} data={data} setData={setData} />
        }
      />
      <Route
        path="/plant-selection"
        element={
          <PlantSelection
            progress={progress}
            data={data}
            setData={setData}
            amplitude={amplitude}
          />
        }
      />
      <Route
        path="/checkout"
        element={
          <Checkout
            progress={progress}
            data={data}
            setData={setData}
            amplitude={amplitude}
          />
        }
      />
      <Route
        path="/shipping-information"
        element={
          <ShippingInformation
            progress={progress}
            data={data}
            setData={setData}
            amplitude={amplitude}
          />
        }
      />
      <Route
        path="/out-of-stock"
        element={
          <OutOfStock
            progress={progress}
            data={data}
            setData={setData}
            amplitude={amplitude}
          />
        }
      />
    </Routes>
  );
};

const App = () => {
  useEffect(() => {
    window.onerror = function (message, source, lineno, colno, error) {
      return true; // Returning true prevents the error from being logged in the console
    };

    // Initialize Amplitude once when the app starts
    const sessionReplayTracking = sessionReplayPlugin({
      sampleRate: 1,
    });

    amplitude.add(sessionReplayTracking);
    amplitude.init("e145f729b681a29219b762a98f89b7c6"); // Replace with your actual Amplitude API key
  }, []); // Empty dependency array ensures this runs only once

  return (
    <Router>
      <RouteHandler />
    </Router>
  );
};

export default App;
