import React, { useRef, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "./assets/logo.svg";
import { ReactComponent as BackArrow } from "./assets/back-arrow.svg";
import rightArrow from "./assets/right-arrow.svg";
import { ReactComponent as LightHighIcon } from "./assets/light-high.svg";
import { ReactComponent as LightMediumIcon } from "./assets/light-medium.svg";
import { ReactComponent as LightLowIcon } from "./assets/light-low.svg";

const LightConditions = ({ progress, data, setData, resources }) => {
  const navigate = useNavigate();
  const [isAnimating, setIsAnimating] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);

  const handleChoice = (lightConditions) => {
    setData({ ...data, lightConditions });
    navigate("/loading-screen");
  };

  const handleImageClick = () => {
    setIsAnimating(true);

    // Reset the animation state after the animation ends (1 second in this case)
    setTimeout(() => {
      setIsAnimating(false);
    }, 1000); // Match the duration of the CSS animation
  };

  return (
    <div className="page question-with-image">
      <div className="notificationheader">
        <Link to="/how-it-works" className="back-button">
          <BackArrow width="24" height="24" />
        </Link>
        <div className="notificationtext">10% Off special on all plants!</div>
      </div>
      <div className="progress" style={{ width: `${progress}%` }} />
      <div className="content">
        <img className="logo-icon" alt="" src={logo} />
        <div className="title">Light Matters</div>
        <div className="subtitle">
          What kind of light does your space get? Don't worry, we've got the
          perfect plant for every corner!
        </div>
        <img
          className={`light-explainer image-loading ${
            imageLoaded ? "image-loaded" : ""
          }`}
          src={
            "https://res.cloudinary.com/djlwg6kex/image/upload/light-explainer_mfrtau.jpg"
          }
          onClick={handleImageClick}
          onLoad={() => {
            setImageLoaded(true);
          }}
        />
        <div className="lc-button-container">
          <div
            onClick={() => handleChoice("bright")}
            className={`lc-option ${isAnimating ? "animate" : ""}`}
          >
            <LightHighIcon className="lc-option-icon" />
            <div className="lc-option-text">
              <div className="lc-option-title">Bright Direct Light</div>
              <div className="lc-option-subtitle">A sun-lover's paradise</div>
            </div>
          </div>
          <div
            onClick={() => handleChoice("medium")}
            className={`lc-option ${isAnimating ? "animate" : ""}`}
          >
            <LightMediumIcon className="lc-option-icon" />
            <div className="lc-option-text">
              <div className="lc-option-title">Medium Light</div>
              <div className="lc-option-subtitle">Bright but not intense</div>
            </div>
          </div>
          <div
            onClick={() => handleChoice("low")}
            className={`lc-option ${isAnimating ? "animate" : ""}`}
          >
            <LightLowIcon className="lc-option-icon" />
            <div className="lc-option-text">
              <div className="lc-option-title">Low Light</div>
              <div className="lc-option-subtitle">Minimal natural light</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(LightConditions);
