import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "./assets/logo.svg";
import { ReactComponent as BackArrow } from "./assets/back-arrow.svg";
import { ReactComponent as BackArrowBlack } from "./assets/back-arrow-black.svg";
import rightArrow from "./assets/right-arrow.svg";
import { ReactComponent as ShippingIcon } from "./assets/shipping-icon.svg";
import { ReactComponent as GuaranteeIcon } from "./assets/guarantee-icon.svg";

const statesList = [
  { value: "AL", label: "Alabama" },
  { value: "AK", label: "Alaska" },
  { value: "AZ", label: "Arizona" },
  { value: "AR", label: "Arkansas" },
  { value: "CA", label: "California" },
  { value: "CO", label: "Colorado" },
  { value: "CT", label: "Connecticut" },
  { value: "DE", label: "Delaware" },
  { value: "FL", label: "Florida" },
  { value: "GA", label: "Georgia" },
  { value: "HI", label: "Hawaii" },
  { value: "ID", label: "Idaho" },
  { value: "IL", label: "Illinois" },
  { value: "IN", label: "Indiana" },
  { value: "IA", label: "Iowa" },
  { value: "KS", label: "Kansas" },
  { value: "KY", label: "Kentucky" },
  { value: "LA", label: "Louisiana" },
  { value: "ME", label: "Maine" },
  { value: "MD", label: "Maryland" },
  { value: "MA", label: "Massachusetts" },
  { value: "MI", label: "Michigan" },
  { value: "MN", label: "Minnesota" },
  { value: "MS", label: "Mississippi" },
  { value: "MO", label: "Missouri" },
  { value: "MT", label: "Montana" },
  { value: "NE", label: "Nebraska" },
  { value: "NV", label: "Nevada" },
  { value: "NH", label: "New Hampshire" },
  { value: "NJ", label: "New Jersey" },
  { value: "NM", label: "New Mexico" },
  { value: "NY", label: "New York" },
  { value: "NC", label: "North Carolina" },
  { value: "ND", label: "North Dakota" },
  { value: "OH", label: "Ohio" },
  { value: "OK", label: "Oklahoma" },
  { value: "OR", label: "Oregon" },
  { value: "PA", label: "Pennsylvania" },
  { value: "RI", label: "Rhode Island" },
  { value: "SC", label: "South Carolina" },
  { value: "SD", label: "South Dakota" },
  { value: "TN", label: "Tennessee" },
  { value: "TX", label: "Texas" },
  { value: "UT", label: "Utah" },
  { value: "VT", label: "Vermont" },
  { value: "VA", label: "Virginia" },
  { value: "WA", label: "Washington" },
  { value: "WV", label: "West Virginia" },
  { value: "WI", label: "Wisconsin" },
  { value: "WY", label: "Wyoming" },
  { value: "DC", label: "District of Columbia" },
  { value: "AS", label: "American Samoa" },
  { value: "GU", label: "Guam" },
  { value: "MP", label: "Northern Mariana Islands" },
  { value: "PR", label: "Puerto Rico" },
  { value: "VI", label: "U.S. Virgin Islands" },
];

const ShippingInformation = ({ progress, data }) => {
  const navigate = useNavigate();
  const [shippingDetails, setShippingDetails] = useState({
    fullName: "",
    email: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    zipCode: "",
  });

  const [invalidFields, setInvalidFields] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setShippingDetails({ ...shippingDetails, [name]: value });
    if (invalidFields[name]) {
      setInvalidFields({ ...invalidFields, [name]: false });
    }
  };

  const validateForm = () => {
    const newInvalidFields = {};
    let isValid = true;

    Object.entries(shippingDetails).forEach(([key, value]) => {
      if (key !== "address2" && !value.trim()) {
        newInvalidFields[key] = true;
        isValid = false;
      }
    });

    const emailRegex = /@.*\./;
    if (!emailRegex.test(shippingDetails.email)) {
      newInvalidFields.email = true;
      isValid = false;
    }

    setInvalidFields(newInvalidFields);
    return isValid;
  };

  const handleSubmit = () => {
    if (validateForm()) {
      navigate("/out-of-stock");
    }
  };

  return (
    <div className="page">
      <div className="notificationheader">
        <Link to="/checkout" className="back-button">
          <BackArrow width="24" height="24" />
        </Link>
        <div className="notificationtext">Enter your shipping information</div>
      </div>
      <div className="progress" style={{ width: `${progress}%` }} />
      <div className="content checkout-content">
        <img className="logo-icon" alt="" src={logo} />
        <div className="title">Shipping Information</div>
        <div className="subtitle">Please provide your shipping details</div>
        <div
          className="plant-selection-searching-plants-filters-list"
          style={{ padding: "0px", marginBottom: "16px" }}
        >
          <div
            className="plant-selection-searching-plants-filter-item"
            style={{ background: "none", padding: "0px 5px" }}
          >
            <ShippingIcon className="searching-plants-check-icon" />
            <span>Free shipping included</span>
          </div>
          <div
            className="plant-selection-searching-plants-filter-item"
            style={{
              borderLeft: "1px solid rgb(209 209 209)",
              background: "none",
              padding: "0px 5px",
            }}
          >
            <GuaranteeIcon className="searching-plants-check-icon" />
            <span>90-day plant guarantee</span>
          </div>
        </div>
        <form className="shipping-form">
          <input
            type="text"
            name="fullName"
            value={shippingDetails.fullName}
            onChange={handleInputChange}
            placeholder="Full Name"
            className={invalidFields.fullName ? "invalid-field" : ""}
            required
          />
          <input
            type="email"
            name="email"
            value={shippingDetails.email}
            onChange={handleInputChange}
            placeholder="Email Address"
            className={invalidFields.email ? "invalid-field" : ""}
            required
          />
          <input
            type="text"
            name="address1"
            value={shippingDetails.address1}
            onChange={handleInputChange}
            placeholder="Street Address"
            className={invalidFields.address1 ? "invalid-field" : ""}
            required
          />
          <input
            type="text"
            name="address2"
            value={shippingDetails.address2}
            onChange={handleInputChange}
            placeholder="Apartment, suite, etc. (optional)"
          />
          <input
            type="text"
            name="city"
            value={shippingDetails.city}
            onChange={handleInputChange}
            placeholder="City"
            className={invalidFields.city ? "invalid-field" : ""}
            required
          />
          <div className="state-zip-container">
            <select
              name="state"
              value={shippingDetails.state}
              onChange={handleInputChange}
              className={invalidFields.state ? "invalid-field" : ""}
              required
            >
              <option value="">State</option>
              {statesList.map((state) => (
                <option key={state.value} value={state.value}>
                  {state.label}
                </option>
              ))}
            </select>
            <input
              type="text"
              name="zipCode"
              value={shippingDetails.zipCode}
              onChange={handleInputChange}
              placeholder="ZIP Code"
              className={invalidFields.zipCode ? "invalid-field" : ""}
              required
            />
          </div>
        </form>

        <p className="subtitle shipping-time">
          Estimated shipping time is 3-5 business days
        </p>
      </div>

      <div className="select-plants-next-fixed-bottom-container">
        <div className="select-plants-next-bottom-content">
          <Link to="/checkout" className="select-plants-next-total-back">
            <BackArrowBlack width="36" height="36" />
          </Link>
          <div className="select-plants-next-total-price">
            <div className="select-plants-next-total-price-price">
              ${data.discountedPrice}
            </div>
            <div className="select-plants-next-total-price-label">
              Total for {data.plants.length} plant
              {data.plants.length > 1 ? "s" : ""}
            </div>
          </div>
          <div onClick={handleSubmit} className="cta cta-checkout">
            <div className="cta-text">Next</div>
            <img className="right-arrow" alt="" src={rightArrow} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(ShippingInformation);
