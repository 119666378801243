import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "./assets/logo.svg";
import rightArrow from "./assets/right-arrow.svg";
import { ReactComponent as BackArrow } from "./assets/back-arrow.svg";

const PlantIcon = ({ className }) => (
  <svg className={className} viewBox="0 0 24 24">
    <path d="M12 22c4.97 0 9-4.03 9-9-4.97 0-9 4.03-9 9zM5.6 10.25c0 1.38 1.12 2.5 2.5 2.5.53 0 1.01-.16 1.42-.44l-.02.19c0 1.38 1.12 2.5 2.5 2.5s2.5-1.12 2.5-2.5l-.02-.19c.4.28.89.44 1.42.44 1.38 0 2.5-1.12 2.5-2.5 0-1-.59-1.85-1.43-2.25.84-.4 1.43-1.25 1.43-2.25 0-1.38-1.12-2.5-2.5-2.5-.53 0-1.01.16-1.42.44l.02-.19C14.5 2.12 13.38 1 12 1S9.5 2.12 9.5 3.5l.02.19c-.4-.28-.89-.44-1.42-.44-1.38 0-2.5 1.12-2.5 2.5 0 1 .59 1.85 1.43 2.25-.84.4-1.43 1.25-1.43 2.25zM12 5.5c1.38 0 2.5 1.12 2.5 2.5s-1.12 2.5-2.5 2.5S9.5 9.38 9.5 8s1.12-2.5 2.5-2.5zM3 13c0 4.97 4.03 9 9 9 0-4.97-4.03-9-9-9z" />
  </svg>
);

const CheckIcon = ({ className }) => (
  <svg className={className} viewBox="0 0 24 24">
    <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41L9 16.17z" />
  </svg>
);

const LoadingScreen = ({ progress, data, setData }) => {
  const navigate = useNavigate();
  const [progressValue, setProgressValue] = useState(0);
  const [plantsFound, setPlantsFound] = useState(0);
  const [isSearching, setIsSearching] = useState(false);
  const [showCheck, setShowCheck] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);
  const [filters, setFilters] = useState([]);

  // New state for controlling element visibility
  const [showSubtitle, setShowSubtitle] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [showProgressCircle, setShowProgressCircle] = useState(false);

  useEffect(() => {
    // clean plant selection from data
    setData({ ...data, plants: [] });

    if (data.hasKids) {
      setFilters((prevFilters) => [...prevFilters, "Kids friendly"]);
    }

    if (data.hasPets) {
      setFilters((prevFilters) => [...prevFilters, "Pets friendly"]);
    }

    if (data.lightConditions === "medium") {
      setFilters((prevFilters) => [...prevFilters, "Medium light"]);
    }

    if (data.lightConditions === "low") {
      setFilters((prevFilters) => [...prevFilters, "Low light"]);
    }

    if (data.lightConditions === "bright") {
      setFilters((prevFilters) => [...prevFilters, "Bright direct light"]);
    }

    // Regardless of filters
    setFilters((prevFilters) => [...prevFilters, "Self watering pot"]);

    // Sequence of animations
    setTimeout(() => setShowSubtitle(true), 300);
    setTimeout(() => setShowFilters(true), 600);
    setTimeout(() => setShowProgressCircle(true), 900);
    setTimeout(() => {
      startSearching();
    }, 2000);
  }, []);

  const startSearching = () => {
    setIsSearching(true);
    const duration = 3000;
    const interval = 16;
    const steps = duration / interval;
    let step = 0;

    const timer = setInterval(() => {
      step++;
      const newProgressValue = Math.min((step / steps) * 100, 100);
      setProgressValue(newProgressValue);
      setPlantsFound(Math.min(Math.floor((newProgressValue / 100) * 132), 132));

      if (newProgressValue >= 100) {
        clearInterval(timer);
        setIsSearching(false);
        setTimeout(() => setShowCheck(true), 300);
        setTimeout(() => {
          setIsCompleted(true);
          handleShowPlants(true);
        }, 800);
      }
    }, interval);
  };

  const handleShowPlants = () => {
    navigate("/plant-selection");
  };

  return (
    <div className="page">
      <div className="notificationheader">
        <Link to="/how-it-works" className="back-button">
          <BackArrow width="24" height="24" />
        </Link>
        <div className="notificationtext">10% Off special on all plants!</div>
      </div>
      <div className="progress" style={{ width: `${progress}%` }} />
      <div className={`content ${isCompleted ? "hiddenAnimation" : ""}`}>
        <div className={`title loading-title`}>Searching...</div>
        <div
          className={`subtitle loading-subtitle ${
            showSubtitle ? "fade-in" : ""
          }`}
        >
          Matching the perfect plants to your home:
        </div>

        <div
          className={`searching-plants-filters-list ${
            showFilters ? "fade-in" : ""
          }`}
        >
          {filters.map((filter, index) => (
            <div key={index} className="searching-plants-filter-item">
              <CheckIcon className="searching-plants-check-icon" />
              <span>{filter}</span>
            </div>
          ))}
        </div>
        <div
          className={`searching-plants-progress-container ${
            showProgressCircle ? "fade-in" : ""
          }`}
        >
          <div
            className="searching-plants-progress-circle"
            style={{
              background: `conic-gradient(#00796B ${progressValue}%, #E0E0E0 ${progressValue}% 100%)`,
            }}
          ></div>
          <div className="searching-plants-progress-overlay"></div>
          <div className="searching-plants-icon-container">
            <PlantIcon
              className={`searching-plants-icon ${
                isSearching ? "visible" : ""
              }`}
            />
            <CheckIcon
              className={`searching-plants-icon ${showCheck ? "visible" : ""}`}
            />
          </div>
        </div>
        <p
          className={`searching-plants-count ${
            showProgressCircle ? "fade-in" : ""
          }`}
        >
          {isSearching ? `Searching ${plantsFound} plants` : `Found 6 plants`}
        </p>
      </div>
    </div>
  );
};

export default React.memo(LoadingScreen);
