import React, { useState, useRef, useEffect, useCallback } from "react";
import "./ProcessCarousel.css";

const ProcessCarousel = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const carouselRef = useRef(null);
  const [image1Loaded, setImage1Loaded] = useState(false);
  const [image2Loaded, setImage2Loaded] = useState(false);

  const steps = [
    {
      image:
        "https://res.cloudinary.com/djlwg6kex/image/upload/hiw-1_dhqwf9.jpg",
      imageLoaded: image1Loaded,
      description: "Fill the water container once a month",
      setImageLoaded: setImage1Loaded,
    },
    {
      image:
        "https://res.cloudinary.com/djlwg6kex/image/upload/hiw-2_rjnkwb.jpg",
      imageLoaded: image2Loaded,
      description:
        "The plant will absord water as needed and thrive, hassle-free!",
      setImageLoaded: setImage2Loaded,
    },
  ];

  return (
    <div className="hiw-carousel-container">
      <div>
        <div className="hiw-carousel-content">
          {steps.map((step, index) => (
            <div key={index} className="hiw-carousel-item">
              <div key={index} className={`hiw-carousel-indicator`}>
                {index + 1}
              </div>
              <div className="hiw-carousel-description subtitle">
                {step.description}
              </div>

              <div className="hiw-carousel-image-container">
                <img
                  src={step.image}
                  alt={`Step ${index + 1}`}
                  className={`hiw-carousel-image image-loading ${
                    step.imageLoaded ? "image-loaded" : ""
                  }`}
                  onLoad={() => step.setImageLoaded(true)}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProcessCarousel;
