import React, { useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import logo from "./assets/logo.svg";
import { ReactComponent as BackArrow } from "./assets/back-arrow.svg";
import rightArrow from "./assets/right-arrow.svg";
import tech from "./assets/self-watering-tech.svg";
import selfWateringIllustration from "./assets/how-it-works.png"; // You'll need to create this illustration
import ProcessCarousel from "./Components/ProcessCarousel/ProcessCarousel";

const HowItWorks = ({ progress }) => {
  return (
    <div className="page how-it-works">
      {" "}
      {/* Reusing 'home' class for consistent structure */}
      <div className="notificationheader">
        <Link to="/" className="back-button">
          <BackArrow width="24" height="24" />
        </Link>
        <div className="notificationtext">10% Off special on all plants!</div>
      </div>
      <div className="progress" style={{ width: `${progress}%` }} />
      <div className="content extra-pad">
        <img className="logo-icon" alt="" src={logo} />
        <div className="title">How it works</div>
        <div className="how-it-works-subtitle">
          Self watering technology that keeps your plants alive and healthy 🌱
        </div>
        <ProcessCarousel />
        <div className="how-it-works-subtitle">
          Next, let's match you with the best plants for your home!
        </div>
        <Link to="/kids-friendly" className="cta">
          <div className="cta-text">Find a plant</div>
          <img className="right-arrow" alt="" src={rightArrow} />
        </Link>
      </div>
    </div>
  );
};

export default React.memo(HowItWorks);
